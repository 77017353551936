////
/// Defines variables for the base colors utilized in the app.
/// ---
/// Component stylesheets should make heavy use of the variables declared here and their aliases.
/// This way, sites based on the white-site can easily change their look and feel by overriding
/// these variables with their brand colors.
/// ---
/// DO NOT, however, declare any component-specific variables in here (e.g. `header-background`).
/// Mapping the colors to the specific places in the app should be handled by tier 2 of the styling
/// layer, or in rare cases, within `_contextualColors.scss`.
/// ---
/// @see Tier 1 in `docs/guides/brand-styling.md` for the theming guide.
/// @group variables/baseColors
////

// Override some of the colors from the white-site.
@forward "@/base/styles/variables/colors/baseColors" with (

    $color-primary-100: #726e68,
    $color-primary-200: #615e59,
    $color-primary-300: #524f4c,
    $color-primary-400: #474643,
    $color-primary-500: #353432, // Root Primary Color
    $color-primary-600: #272625,
    $color-primary-700: #131210,
    $color-primary-800: #0f0e0c,
    $color-primary-900: #090906,

    $color-secondary-100: #f5f5f5, // Additional Direct Palette Color
    $color-secondary-200: #ededed,
    $color-secondary-300: #dfdfdf,
    $color-secondary-400: #c8c8c8,
    $color-secondary-500: #acacac, // Root Secondary Color
    $color-secondary-600: #999999,
    $color-secondary-700: #888888,
    $color-secondary-800: #7b7b7b,
    $color-secondary-900: #676767,

    $color-tertiary-100: #f8f5ee,
    $color-tertiary-200: #efe7d2,
    $color-tertiary-300: #e0d0a8,
    $color-tertiary-400: #ceb176,
    $color-tertiary-500: #c09853, // Root Tertiary Color
    $color-tertiary-600: #b08342,
    $color-tertiary-700: #976837,
    $color-tertiary-800: #794f2f,
    $color-tertiary-900: #66422d,

);
