@use "@/styles/variables" as *;

.wrapper {
  margin: $space-md auto;
  max-width: 1200px;
  width: 80%;

  @media (max-width: $breakpoint-md) {
    width: 100%;
  }
}

.wrapperWide {
  margin: auto;
  max-width: 1200px;

  @media (max-width: $breakpoint-md) {
    width: 100%;
  }
}

.textCenter {
  text-align: center;
}

.textLeft {
  text-align: left;
}

.textRight {
  text-align: right;
}
