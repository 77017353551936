@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

$selected-color-indicator-space: -5px;
$color-button-size: 34px;

.product {
  background-color: $color-secondary-100;
}

.colorPicker {
  display: flex;
  gap: $space-sm;
  margin: $space-md 0;
  padding-left: $space-md;
}

.colorButtonContainer {
  position: relative;
  cursor: pointer;
}

.colorButton {
  height: $color-button-size;
  width: $color-button-size;
  border-radius: 50%;
  cursor: pointer;
}

.selectedColorIndicator {
  position: absolute;

  /*
   * This is a negative value to make the indicator overlap the color button and
   * center it creating a small white space around it.
   */
  top: $selected-color-indicator-space;
  left: $selected-color-indicator-space;
  right: $selected-color-indicator-space;
  bottom: $selected-color-indicator-space;
  border-radius: 50%;
  border: 2px solid $color-black;
}
