////
/// Defines variables relating to default spacing between elements.
/// @group variables/spacing
////

// Spacing
$space-xxxxs: 2px;
$space-xxxs: 4px;
$space-xxs: 8px;
$space-xs: 12px;
$space-sm: 16px;
$space-base: 20px;
$space-md: 24px;
$space-lg: 32px;
$space-xl: 48px;
$space-xxl: 64px;
$space-xxxl: 96px;
$space-xxxxl: 128px;
