.printable {
  visibility: visible;
}

@mixin print {
  @media print {
    @content;
  }
}

.nonPrintable {
  display: contents;

  @media print {
    display: none !important;
  }
}
