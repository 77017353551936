////
/// Defines variables relating to typography and type setting.
/// @group variables/typography
////

// Font Size
$font-size-xxs: 12px !default;
$font-size-xs: 14px !default;
$font-size-sm: 15px !default;
$font-size-base: 16px !default;
$font-size-md: 18px !default;
$font-size-lg: 20px !default;
$font-size-xl: 26px !default;
$font-size-xxl: 30px !default;
$font-size-xxxl: 36px !default;
$font-size-xxxxl: 42px !default;
$font-size-xxxxxl: 48px !default;

// Font Weight
$font-weight-xthin: 50 !default;
$font-weight-thin: 100 !default;
$font-weight-base: 300 !default;
$font-weight-light: 300 !default;
$font-weight-regular: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-heavy: 600 !default;
$font-weight-bold: 700 !default;
$font-weight-bolder: 800 !default;
$font-weight-black: 900 !default;

// Line Height
$line-height-none: 0 !default;
$line-height-relaxed: 1.5 !default;
$line-height-relaxed-sm: 1.3 !default;

// Letter Spacing
$letter-spacing-headline: 1px !default;
$letter-spacing-relaxed: 2px !default;
$letter-spacing-small-text: 1px !default;
