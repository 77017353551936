@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.wrapper {
  padding-inline: 0;
}

// Color Properties
.primaryColor {
  color: $color-primary;
}

// Display properties.
.d-none {
  display: none;
}

.d-flex {
  display: flex;
}

.mobile-only {
  @include lg-min {
    display: none;
  }
}

.desktop-only {
  @include md-max {
    display: none;
  }
}

// Margin properties.
.m-0 {
  margin: 0 !important;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

/*
"with" styles are intended to be used on teaser style overlays. The goal is to make the teaser overlay amenable to
smaller style changes. For example, if the font size of the overlay needs to be changed, the change can be made in one
place instead of having to change the font size in code or creating classes for each font size.
*/
.withFontSize {
  // Font size included via variable
  font-size: clamp(
    var(--min-font-size, 1rem),
    var(--font-size, 1rem),
    var(--max-font-size, 1rem)
  ) !important;
}

.withFontWeight {
  // Font weight included via variable
  font-weight: var(--font-weight, $font-weight-regular) !important;
}

.withLineHeight {
  // Line height included via variable
  line-height: var(--line-height) !important;
}

.withWidth {
  // Width included via variable
  width: clamp(
    var(--min-width, var(--width, auto)),
    var(--width, auto),
    var(--max-width, auto)
  );
}

.withHeight {
  // Height included via variable
  height: clamp(
    var(--min-height, auto),
    var(--height, auto),
    var(--max-height, auto)
  );
}

.withGap {
  // Gap included via variable
  gap: var(--gap, $space-md);
}

.withColor {
  // Color included via variable
  color: var(--color, $color-font-base);
}

.withHoverColor {
  // Hover color included via variable
  &:hover {
    color: var(--hover-color, $color-primary);
  }
}

.withBackground {
  // Background included via --background
  background: var(--background, rgba(0, 0, 0, 0));
}

.pt {
  // Padding top included by setting --pt in settings;
  padding-top: var(--pt) !important;
}

.pb {
// Padding bottom included by setting --pb in settings;
  padding-bottom: var(--pb) !important;
}

.pl {
  // Padding left included by setting --pl in settings;
  padding-left: var(--pl) !important;
}

.pr {
  // Padding right included by setting --pr in settings;
  padding-right: var(--pr) !important;
}

.px {
  // Padding x included by setting --px in settings;
  padding-inline: var(--px) !important;
}

.py {
// Padding y included by setting --py in settings;
  padding-block: var(--py) !important;
}

.mt {
  // Margin top included by setting --mt in settings;
  margin-top: var(--mt) !important;
}

.mb {
  // Margin bottom included by setting --mb in settings;
  margin-bottom: var(--mb) !important;
}

.mr {
  // Margin right included by setting --mr in settings;
  margin-right: var(--mr) !important;
}

.mx {
  // Margin x included by setting --mx in settings;
  margin-inline: var(--mx) !important;
}

.my {
  // Margin y included by setting --my in settings;
  margin-block: var(--my) !important;
}

.darkText {
  color: $color-font-base;
}

.lightText {
  color: $color-light;
}

.primaryText {
  color: $color-primary;
}

// Page specific styles
.plpHeadline {
  font-size: clamp($font-size-xxl, 2vw, $font-size-xxxl);
  padding-inline: $space-md;
  font-weight: $font-weight-regular;
}

.plpSubheadline {
  font-size: clamp($font-size-base, 1.2vw, $font-size-lg);
  margin-bottom: $space-md;
  line-height: $line-height-relaxed;
}

.cartValuePropositions {
  display: flex;
  flex-direction: column;

  &:first-child {
    > div {
      display: flex;
      flex-direction: column;
      align-items: center;

      @include md-min {
        align-items: flex-end;
      }
    }
  }

  &:last-child {
    > div {
      display: flex;
      flex-direction: column;
      align-items: center;

      @include md-min {
        align-items: flex-start;
      }
    }
  }
}

.headlineSkinny {
  font-weight: normal;
  font-size: clamp($font-size-lg, 1.75vw, $font-size-xxl);
  text-transform: capitalize !important;
}

.headlineLarge {
  font-size: clamp($font-size-lg, 2vw, $font-size-xxxl);
  font-weight: $font-weight-bold;
  line-height: $line-height-relaxed;
}

.headlineMedium {
  font-size: clamp($font-size-lg, 1.75vw, $font-size-xxl);
  font-weight: $font-weight-bold;
  line-height: $line-height-relaxed;
}

.subcopyMedium {
  font-size: clamp($font-size-base, 1.2vw, $font-size-lg);
  margin-bottom: $space-md;
  padding-inline: $space-md;
  line-height: $line-height-relaxed;
}

.subcopyLarge {
  font-size: clamp($font-size-md, 1.5vw, $font-size-xl);
  margin: auto;
  margin-bottom: $space-md;
  padding-inline: $space-md;
  line-height: $line-height-relaxed;
}

// Corporate Social Responsibility
.csrHeadline {
  padding-top: $space-sm;
  width: 100%;

  @include md-min {
    padding-top: $space-md;
  }
}

@include md-min {
  .textCenter {
    text-align: center;
  }

  .textLeft {
    text-align: left;
  }

  .textRight {
    text-align: right;
  }

  .right {
    margin-left: auto !important;
  }

  .left {
    margin-right: auto;
  }

  .center {
    margin-left: auto;
    margin-right: auto;
  }
}

.p-0 {
  padding: 0 !important;
}

.textTransformUnset {
  text-transform: unset !important;
}
