////
/// Defines variables for the base colors utilized in the app.
/// ---
/// Component stylesheets should make heavy use of the variables declared here and their aliases.
/// This way, sites based on the white-site can easily change their look and feel by overriding
/// these variables with their brand colors.
/// ---
/// DO NOT, however, declare any component-specific variables in here (e.g. `header-background`).
/// Mapping the colors to the specific places in the app should be handled by tier 2 of the styling
/// layer, or in rare cases, within `_contextualColors.scss`.
/// ---
/// @see Tier 1 in `docs/guides/brand-styling.md` for the theming guide.
/// @group variables/baseColors
////

$color-primary-100: var(--color-primary-100, #CFD8DC) !default;
$color-primary-200: var(--color-primary-200, #CFD8DC) !default;
$color-primary-300: var(--color-primary-300, #90A4AE) !default;
$color-primary-400: var(--color-primary-400, #78909C) !default;
$color-primary-500: var(--color-primary-500, #607D8B) !default;
$color-primary-600: var(--color-primary-600, #546E7A) !default;
$color-primary-700: var(--color-primary-700, #455A64) !default;
$color-primary-800: var(--color-primary-800, #37474F) !default;
$color-primary-900: var(--color-primary-900, #263238) !default;
$color-secondary-100: var(--color-secondary-100, #D7CCC8) !default;
$color-secondary-200: var(--color-secondary-200, #BCAAA4) !default;
$color-secondary-300: var(--color-secondary-300, #A1887F) !default;
$color-secondary-400: var(--color-secondary-400, #8D6E63) !default;
$color-secondary-500: var(--color-secondary-500, #795548) !default;
$color-secondary-600: var(--color-secondary-600, #6D4C41) !default;
$color-secondary-700: var(--color-secondary-700, #5D4037) !default;
$color-secondary-800: var(--color-secondary-800, #4E342E) !default;
$color-secondary-900: var(--color-secondary-900, #3E2723) !default;
$color-tertiary-100: #d6ffe3 !default;
$color-tertiary-200: #adffcf !default;
$color-tertiary-300: #84ffc3 !default;
$color-tertiary-400: #66ffc3 !default;
$color-tertiary-500: #33ffc3 !default;
$color-tertiary-600: #25dbb7 !default;
$color-tertiary-700: #19b7a8 !default;
$color-tertiary-800: #109393 !default;
$color-tertiary-900: #096f7a !default;
$color-success-100: #e6fcd4 !default;
$color-success-200: #c8f9aa !default;
$color-success-300: #a0ee7d !default;
$color-success-400: #78de5a !default;
$color-success-500: #42c92a !default;
$color-success-600: #29ac1e !default;
$color-success-700: #159015 !default;
$color-success-800: #0d7415 !default;
$color-success-900: #086016 !default;
$color-danger-100: #FFCDD2 !default;
$color-danger-200: #EF9A9A !default;
$color-danger-300: #E57373 !default;
$color-danger-400: #EF5350 !default;
$color-danger-500: #F44336 !default;
$color-danger-600: #E53935 !default;
$color-danger-700: #D32F2F !default;
$color-danger-800: #C62828 !default;
$color-danger-900: #a60000 !default;
$color-warning-100: #fff2cc !default;
$color-warning-200: #ffe299 !default;
$color-warning-300: #ffce66 !default;
$color-warning-400: #ffba3f !default;
$color-warning-500: #ff9900 !default;
$color-warning-600: #db7a00 !default;
$color-warning-700: #b75f00 !default;
$color-warning-800: #934600 !default;
$color-warning-900: #7a3500 !default;
$color-info-100: #d2eaff !default;
$color-info-200: #a4d1ff !default;
$color-info-300: #78b6ff !default;
$color-info-400: #569dff !default;
$color-info-500: #1e74ff !default;
$color-info-600: #1559db !default;
$color-info-700: #0f42b7 !default;
$color-info-800: #092e93 !default;
$color-info-900: #05207a !default;
$color-white: #fff !default;
$color-shading-100: #F5F5F5 !default;
$color-shading-200: #EEEEEE !default;
$color-shading-300: #F7F7F7 !default;
$color-shading-400: #BDBDBD !default;
$color-shading-500: #9E9E9E !default;
$color-shading-600: #757575 !default;
$color-shading-700: #616161 !default;
$color-shading-800: #424242 !default;
$color-shading-900: #212121 !default;
$color-black: #000 !default;

// COLOR ALIASES

// Primaries
$color-primary: $color-primary-500 !default;
$color-primary-light: $color-primary-300 !default;
$color-primary-lighter: $color-primary-100 !default;
$color-primary-dark: $color-primary-700 !default;
$color-primary-darker: $color-primary-900 !default;

// Secondaries
$color-secondary: $color-secondary-500 !default;
$color-secondary-light: $color-secondary-300 !default;
$color-secondary-lighter: $color-secondary-100 !default;
$color-secondary-dark: $color-secondary-700 !default;
$color-secondary-darker: $color-secondary-900 !default;

// Tertiaries
$color-tertiary: $color-tertiary-500 !default;
$color-tertiary-light: $color-tertiary-300 !default;
$color-tertiary-lighter: $color-tertiary-100 !default;
$color-tertiary-dark: $color-tertiary-700 !default;
$color-tertiary-darker: $color-tertiary-900 !default;

// Success
$color-success: $color-success-500 !default;
$color-success-light: $color-success-300 !default;
$color-success-lighter: $color-success-100 !default;
$color-success-dark: $color-success-700 !default;
$color-success-darker: $color-success-900 !default;

// Danger
$color-danger: $color-danger-500 !default;
$color-danger-light: $color-danger-300 !default;
$color-danger-lighter: $color-danger-100 !default;
$color-danger-dark: $color-danger-700 !default;
$color-danger-darker: $color-danger-900 !default;

// Warning
$color-warning: $color-warning-500 !default;
$color-warning-light: $color-warning-300 !default;
$color-warning-lighter: $color-warning-100 !default;
$color-warning-dark: $color-warning-700 !default;
$color-warning-darker: $color-warning-900 !default;

// Info
$color-info: $color-info-500 !default;
$color-info-light: $color-info-300 !default;
$color-info-lighter: $color-info-100 !default;
$color-info-dark: $color-info-700 !default;
$color-info-darker: $color-info-900 !default;

// Whites, grays, and blacks
$color-white-gray: $color-shading-100 !default;
$color-lighter-gray: $color-shading-300 !default;
$color-light-gray: $color-shading-400 !default;
$color-light-gray-2: #C7C7C7 !default;
$color-light-gray-3: #ECECEC !default;
$color-light-gray-4: #CCCCCC !default;
$color-light-gray-5: #E7E7E7 !default;
$color-light-gray-6: #F3F3F3 !default;
$color-gray: $color-shading-500 !default;
$color-dark-gray: $color-shading-600 !default;
$color-darker-gray: $color-shading-700 !default;
$color-black-gray: $color-shading-900 !default;

// Miscellaneous
$color-transparent: #0000 !default; // Black with alpha = 0

// Legacy aliases (deprecated)
$color-dark: $color-primary-dark;
$color-light: $color-white;
$color-error: $color-danger;
$color-error-light: $color-danger-lighter;
$color-error-dark: $color-danger-darker;
$color-blue: $color-info;
$color-gray-1: $color-white-gray;
$color-gray-2: $color-light-gray;
$color-gray-3: $color-gray;
$color-gray-light: $color-lighter-gray;
$color-gray-light-2: $color-white-gray;

// Blog colors
$color-laurel-green: #b6c7af !default;
$color-vegas-gold: #bdae4c !default;
$color-cool-gray: #8491b4 !default;
$color-deep-peach: #f5bf9a !default;
$color-moonstone-blue: #70a1ad !default;
$color-coral: #f3875f !default;
