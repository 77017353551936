////
/// Defines variables relating to typography and type setting.
/// @group variables/typography
////

@forward "@/base/styles/variables/typography" with (
  // Font Size
  $font-size-xxs: 12px,
  $font-size-xs: 14px,
  $font-size-sm: 16px,
  $font-size-base: 18px,
  $font-size-md: 20px,
  $font-size-lg: 22px,
  $font-size-xl: 24px,
  $font-size-xxl: 28px,
  $font-size-xxxxl: 46px,
  $font-size-xxxxxl: 60px,

  // Font Weight
  $font-weight-base: 400,
  $font-weight-bold: 500,

  // Actual 'Bold' font (700) is not used
  // Letter Spacing
  $letter-spacing-headline: 0
);

// Additional Sizes
$font-size-xxxxxxl: 70px;

// FULL FONT SIZE REFERENCE:
//   12px:  $font-size-xxs
//   14px:  $font-size-xs
//   16px:  $font-size-sm
//   18px:  $font-size-base
//   20px:  $font-size-md
//   22px:  $font-size-lg
//   24px:  $font-size-xl
//   28px:  $font-size-xxl
//   36px:  $font-size-xxxl
//   46px:  $font-size-xxxxl
//   60px:  $font-size-xxxxxl
//   70px:  $font-size-xxxxxxl

// FULL FONT WEIGHT REFERENCE:
//   XThin (50):    $font-weight-xthin
//   Thin (100):    $font-weight-thin
//   Light (300):   $font-weight-light
//   Roman (400):   $font-weight-regular, $font-weight-base
//   Medium (500):  $font-weight-medium, $font-weight-bold
