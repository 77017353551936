@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.image {
  width: 100%;
}

.container {
  position: relative;
  overflow: hidden;

  @media (max-width: $breakpoint-md) {
    padding-top: 90px;
  }
}

.link {
  color: $color-black;
  text-decoration: none;
}

.textWrapper {
  position: absolute;
  top: 0;
  left: 0;
  margin: 32px;

  :is(h1, h2, h3, h4, h5, h6, p) {
    margin: 0;
 }
}

.title p {
  margin: $space-xxxs;
}

.textOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 $space-lg;
  z-index: 1;
  line-height: 1;
  color: $color-secondary-900;
  pointer-events: none;
  transition: color 0.3s ease-in-out;
}

.enhancedHoverImage {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0;
  scale: 1.03;
  transition: opacity 0.3s ease-in-out, scale 0.3s ease-in-out;
}

@media (max-width: $breakpoint-md) {
  .enhancedHoverImage {
    display: none;
  }
}

.enhancedHoverWrapper {
  position: relative;
  overflow: hidden;

  .light {
    color: $color-white;
  }

  .dark {
    color: $color-secondary-900;
  }

  &:hover {
    .hoverLight {
      color: $color-white;
    }

    .hoverDark {
        color: $color-secondary-900;
    }

    .enhancedHoverImage {
      opacity: 1;
      scale: 1;
    }
  }
}

.marginXSm {
  margin-left: $space-sm;
  margin-right: $space-sm;
}

.marginXMd {
  margin-left: $space-md;
  margin-right: $space-md;
}

.marginXLg {
  margin-left: $space-lg;
  margin-right: $space-lg;
}

.marginXNone {
  margin-left: 0;
  margin-right: 0;
}

.marginYSm {
  margin-top: $space-sm;
  margin-bottom: $space-sm;
}

.marginYMd {
  margin-top: $space-md;
  margin-bottom: $space-md;
}

.marginYLg {
  margin-top: $space-lg;
  margin-bottom: $space-lg;
}

.marginYNone {
  margin-top: 0;
  margin-bottom: 0;
}
