////
/// Defines utility functions and mixins relating to viewport breakpoints.
/// @group utils/breakpoints
////

@use "@/styles/variables" as *;

// Breakpoint mixins: at least $breakpoint-ABC wide

/// Represents a media query for device widths of "extra small" or greater.
/// @content Any styling that should occur within this breakpoint.
/// @example scss
///   @include xs-min {
///     display: block;
///   }
@mixin xs-min {
  @media (min-width: $breakpoint-xs) {
    @content;
  }
}

/// Represents a media query for device widths of "small" or greater.
/// @content Any styling that should occur within this breakpoint.
/// @example scss
///   @include sm-min {
///     display: block;
///   }
@mixin sm-min {
  @media (min-width: $breakpoint-sm) {
    @content;
  }
}

/// Represents a media query for device widths of "medium" or greater.
/// @content Any styling that should occur within this breakpoint.
/// @example scss
///   @include md-min {
///     display: block;
///   }
@mixin md-min {
  @media (min-width: $breakpoint-md) {
    @content;
  }
}

/// Represents a media query for device widths of "large" or greater.
/// @content Any styling that should occur within this breakpoint.
/// @example scss
///   @include lg-min {
///     display: block;
///   }
@mixin lg-min {
  @media (min-width: $breakpoint-lg) {
    @content;
  }
}

/// Represents a media query for device widths of "extra large" or greater.
/// @content Any styling that should occur within this breakpoint.
/// @example scss
///   @include xl-min {
///     display: block;
///   }
@mixin xl-min {
  @media (min-width: $breakpoint-xl) {
    @content;
  }
}

// Breakpoint mixins: narrower than $breakpoint-ABC

/// Represents a media query for device widths less than "extra small".
/// @content Any styling that should occur within this breakpoint.
/// @example scss
///   @include xxs-max {
///     display: block;
///   }
@mixin xxs-max {
  @media (max-width: ($breakpoint-xs - 1px)) {
    @content;
  }
}

/// Represents a media query for device widths less than "small".
/// @content Any styling that should occur within this breakpoint.
/// @example scss
///   @include xs-max {
///     display: block;
///   }
@mixin xs-max {
  @media (max-width: ($breakpoint-sm - 1px)) {
    @content;
  }
}

/// Represents a media query for device widths less than "medium".
/// @content Any styling that should occur within this breakpoint.
/// @example scss
///   @include sm-max {
///     display: block;
///   }
@mixin sm-max {
  @media (max-width: ($breakpoint-md - 1px)) {
    @content;
  }
}

/// Represents a media query for device widths less than "large".
/// @content Any styling that should occur within this breakpoint.
/// @example scss
///   @include md-max {
///     display: block;
///   }
@mixin md-max {
  @media (max-width: ($breakpoint-lg - 1px)) {
    @content;
  }
}

/// Represents a media query for device widths less than "extra large".
/// @content Any styling that should occur within this breakpoint.
/// @example scss
///   @include lg-max {
///     display: block;
///   }
@mixin lg-max {
  @media (max-width: ($breakpoint-xl - 1px)) {
    @content;
  }
}

// Breakpoint mixins: Specific screen sizes

/// Represents a media query for a device width of "extra extra small".
/// @content Any styling that should occur within this breakpoint.
/// @example scss
///   @include xxs {
///     display: block;
///   }
@mixin xxs {
  @media (max-width: ($breakpoint-xs - 1px)) {
    @content;
  }
}

/// Represents a media query for a device width of "extra small".
/// @content Any styling that should occur within this breakpoint.
/// @example scss
///   @include xs {
///     display: block;
///   }
@mixin xs {
  @media (min-width: $breakpoint-xs) and (max-width: ($breakpoint-sm - 1px)) {
    @content;
  }
}

/// Represents a media query for a device width of "small".
/// @content Any styling that should occur within this breakpoint.
/// @example scss
///   @include sm {
///     display: block;
///   }
@mixin sm {
  @media (min-width: $breakpoint-sm) and (max-width: ($breakpoint-md - 1px)) {
    @content;
  }
}

/// Represents a media query for a device width of "medium".
/// @content Any styling that should occur within this breakpoint.
/// @example scss
///   @include md {
///     display: block;
///   }
@mixin md {
  @media (min-width: $breakpoint-md) and (max-width: ($breakpoint-lg - 1px)) {
    @content;
  }
}

/// Represents a media query for a device width of "large".
/// @content Any styling that should occur within this breakpoint.
/// @example scss
///   @include lg {
///     display: block;
///   }
@mixin lg {
  @media (min-width: $breakpoint-lg) and (max-width: ($breakpoint-xl - 1px)) {
    @content;
  }
}

/// Represents a media query for a device width of "extra large".
/// @content Any styling that should occur within this breakpoint.
/// @example scss
///   @include xl {
///     display: block;
///   }
@mixin xl {
  @media (min-width: $breakpoint-xl) {
    @content;
  }
}

// Breakpoint mixins: Specific device types

/// Represents a media query for a mobile sized device.
/// @content Any styling that should occur within this breakpoint.
/// @example scss
///   @include phone {
///     display: block;
///   }
@mixin phone {
  @media (max-width: ($breakpoint-sm - 1px)) {
    @content;
  }
}

/// Represents a media query for a tablet sized device.
/// @content Any styling that should occur within this breakpoint.
/// @example scss
///   @include tablet {
///     display: block;
///   }
@mixin tablet {
  @media (min-width: $breakpoint-sm) and (max-width: ($breakpoint-lg - 1px)) {
    @content;
  }
}

/// Represents a media query for a desktop sized device.
/// @content Any styling that should occur within this breakpoint.
/// @example scss
///   @include desktop {
///     display: block;
///   }
@mixin desktop {
  @media (min-width: $breakpoint-lg) {
    @content;
  }
}
