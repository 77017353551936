@use "@/styles/variables" as *;
@use "@/styles/helpers/overlayStyles" as *;
@use "@/styles/helpers/content" as *;
@use "@/styles/utils" as *;

@forward "./styles.base.module.scss";

.title {
    font-size: $font-size-base;
}

.body {
    font-size: $font-size-base;
    font-weight: $font-weight-light;
}
